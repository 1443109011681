.purchaseWrapper {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-left: -0.75rem;
    z-index: 1;
}

@media only screen and (min-width: 800px) {
    .purchaseWrapper {
        position: inherit;
        width: 10rem;
        margin-left: 0;
        margin-right: 0.5rem;
    }
}
@font-face {
  font-family: DMSans;
  src: url(./fonts/DMSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: DMSans;
  src: url(./fonts/DMSans-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: DMSans;
  src: url(./fonts/DMSans-Medium.ttf) format('truetype');
  font-style: oblique;
}

body {
  margin: 0;
  font-family: DMSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: DMSans;
}

.popup-content  {
  width: 40%;
  background: white;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 20px;
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  z-index: 1;
  outline: 0;
  padding: 2px 5px;
  line-height: 20px;
  right: -5px;
  top: -5px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.IPID.close {
  padding: 4px 7px;
  top: 0;
  right: 0;
  border-color: black;
}
.package {
  margin: 0 -0.75rem 0.75rem;
  border-bottom: 1px solid #D2D2D2;
}

.package:last-child {
  border-bottom: 0;
}

.packagesHeader {
  color: #CB7469;
  font-weight: bold;
  line-height: 1.32em;
  font-size: 0.875em;
}

.packageLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px 12px;
}

.packageTitle, .packagePrice {
  display: inline-block;
  font-size: 1.375em;
}

.packagePrice {
  font-weight: bold;
  padding-left: 5px;
}

.packageText {
  font-size: 0.75em;
  color: #575757;
}

.packagesWrapper input {
  position: absolute;
  margin:0;padding:0;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  visibility: hidden;
} 

.packageButtonWrapper {
  height: 35px;
  width: 55px;
  color: #575757;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #575757;
  border-radius: 5px;
}

.packageButton.selected {
  width: 17px;
}

.packagesWrapper input:checked + .packageLabel .packageButtonWrapper {
  background: black;
  color: white;
}

.packagesWrapper input:checked + .packageLabel .packageButton.unselected {
  display: none;
}

.packagesWrapper input:not(:checked) + .packageLabel .packageButton.selected {
  display: none;
}

@media only screen and (min-width: 800px) {
  .packagesWrapper {
    margin-bottom: 1rem;
  }

  .package {
    width: 28rem;
    margin: 0;
  }

  .packagesHeader {
    font-size: 1.25em;
  }

  .packageLabel {
    padding: 1.25em 0.75rem;
  }

  .packageTitle {
    font-size: 1em;
    line-height: 1em;
  }

  .packagePrice {
    font-size: 1em;
  }

  .packageText {
    font-size: 0.85em;
  }

  .packageButtonWrapper {
    width: 65px;
  }
}
.email {
    font-size: 0.875em;
    height: 60px;
    background: #FCFCFC;
    border: 1px solid #D2D2D2;
    width: calc(100% - 24px);
    padding-left: 20px;
    outline: 0;
    -webkit-appearance: none;
}

@media only screen and (min-width: 800px) {
    .email {
        width: 15.5rem;
    }
}
.modal.fade .modal-dialog {
	transform: translate(0, -150px);
}

.modal.show .modal-dialog {
	transform: translate(0, 0);
}

.modal-dialog-vertical {
	max-width: 350px;
	height: 100%;
	margin: 0;
}

.modal-dialog-vertical .modal-content {
	height: inherit;
	border-width: 0 1px 0 0;
	border-radius: 0;
}

.modal-dialog-vertical .modal-logo {
	width: 80%;
	margin: 2rem auto;
}

.modal-dialog-vertical .modal-header {
	border-radius: inherit;
}

.modal-product-wrapper {
	margin-bottom: 10px;
}

.modal-dialog-vertical .modal-body {
	overflow-y: auto;
}

.modal.fade .modal-dialog-vertical {
	transform: translateX(-100%);
}

.modal.show .modal-dialog-vertical {
	transform: translateX(0);
}

.modal.fixed-right {
	padding-right: 0 !important;
}

.modal.fixed-right .modal-dialog-vertical {
	margin-left: auto;
}

.modal.fixed-right.fade .modal-dialog-vertical {
	transform: translateX(100%);
}

.modal.fixed-right.show .modal-dialog-vertical {
	transform: translateX(0);
}

.modal-card {
	margin-bottom: 0;
}

.modal-card .card-body {
	overflow-y: auto;
	max-height: 350px;
}

.modal-open {
	overflow: hidden;
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal {
	position: fixed;
	z-index: 1050;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	overflow: hidden;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}

.modal.fade .modal-dialog {
	transition: transform 0.3s ease-out;
	transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}

.modal.show .modal-dialog {
	transform: translate(0, 0);
}

.modal-dialog-centered {
	display: flex;
	min-height: calc(100% - (0.5rem * 2));
	align-items: center;
}

.modal-dialog-centered::before {
	display: block;
	height: calc(100vh - (0.5rem * 2));
	content: "";
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	border: 1px solid transparent;
	border-radius: 0.5rem;
	outline: 0;
	background-color: #fff;
	background-clip: padding-box;
}

.modal-backdrop {
	position: fixed;
	z-index: 1040;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #12263f;
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: flex;
	padding: 1.5rem;
	border-bottom: 1px solid #e3ebf6;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	align-items: flex-start;
	justify-content: space-between;
}

.modal-header .close {
	margin: -1.5rem -1.5rem -1.5rem auto;
	padding: 1.5rem;
}

.modal-title {
	line-height: 1.5;
	margin-bottom: 0;
}

.modal-body {
	position: relative;
	padding: 1.5rem;
	padding-bottom: 0px;
}

.modal-footer {
	display: flex;
	padding: 1.5rem;
	border-top: 1px solid #e3ebf6;
	align-items: center;
	justify-content: flex-end;
}

.modal-footer > :not(:first-child) {
	margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
	margin-right: 0.25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	overflow: scroll;
	width: 50px;
	height: 50px;
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 800px;
	}
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}

	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2));
	}

	.modal-dialog-centered::before {
		height: calc(100vh - (1.75rem * 2));
	}

	.modal-sm {
		max-width: 300px;
	}
}
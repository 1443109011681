.blocketHeader {
    text-align: center;
}

.blocketHeaderLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    padding-top: 1.125em;
    height: 3.5em;
    background: black;
}

.blocketHeaderImg {
    height: 1.75rem;
    margin-right: 5px;
}

.BlocketHeaderBanner {
    font-size: 0.875em;
    background: #CB7469;
    color: white;
    height: 40px;
    line-height: 40px;
}

@media only screen and (min-width: 800px) {    
    .blocketHeaderLogo {
        height: 5.313rem;
        line-height: 5em;
        padding-top: 0;
    }

    .blocketHeaderImg {
        height: 2.125em;
        margin-right: 0;
    }
}
.blocket p {
  font-size: 12px;
}

.blocket .gray {
  color: #9F9F9F;
}

.blocket ::placeholder {
  color: #9D9D9D;
}

.blocket {
  background: white;
  padding: 2rem 0.75rem;
  margin: auto;
}

.blocketInner {
  margin-bottom: 40px;
}

.blocketInlineTitle {
  display: flex;
  align-items: center;
}

.blocket .inlineRightWrapper {
  margin-top: 30px;
}

@media only screen and (min-width: 800px) {
  .blocket {
    padding: 3rem 1rem;
    max-width: 1280px;
  }

  .blocketInner {
    display: inline-flex;
    width: 100%;
    margin-bottom: 5rem;
    justify-content: center;
  }

  .blocket .inlineRightWrapper {
    width: 28rem;
    margin-top: 0;
    padding: 0 2rem;
  }

  .blocket .inline {
    display: inline-flex;
    align-items: center;
    width: 28rem;
    justify-content: space-between;
    margin-right: -0.75rem;
  }

  .blocket .bankId {
    width: 180px;
  } 
}
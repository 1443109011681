.titleWrapper {
    display: block;
    font-weight: 700;
    margin-bottom: 2rem;
}
.title {
    color: #CB7469;
    margin: 0;
    font-size: 1.5em;
}

.text {
    font-size: 1em;
}

@media only screen and (min-width: 800px) {
    .titleWrapper {
        max-width: 900px;
    }
    .title {
        font-size: 2.5em;
    }

    .text {
        font-size: 1.5em;
    }
}
.failureWrapper {
    min-height: 100vh;
}

.failure {
    background: rgba(255, 160, 122, .4);
    color: #D53720;
    min-height: 85vh;
    text-align: center
}

.backButton {
    padding: 20px;
    position: absolute;
    cursor: pointer;
}

.arrow {
    float: left;
    height: 25px;
}

.backButton > input {
    background-color: unset;
    border: none;
    color: unset;
    font-weight: bold;
    cursor: pointer;
}

.backButton > input:focus {
    outline: unset;
}

.thumbs {
    height: 40px;
    margin-top: 20vh;
}

.failure p {
    font-size: 18px;
    margin: 0px 20vw;
}

.mail {
    color: #D53720;
    text-decoration: none;
}

.mail:hover {
    color: #D53720;
}

.button {
    display: inline-block;
    min-width: 85px;
    color: #D53720;
    border: 2px solid #D53720;
    text-decoration: none;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-decoration: none;
    padding: 22.5px 36.5px;
    margin: 30px 0;
    text-align: center;
    cursor: pointer;
}

.button:hover {
    border-color: rgba(255, 160, 122, .4);
}

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background: white;
    left: 0;
    margin: 1em 0;
}
.soldItemName {
    font-weight: bold;
    display: inline-block;
    font-size: 1.125em;
    line-height: 1.25em;
    margin-left: 0.75rem;
    color: #000000;
}

.soldItemNamePretext {
    color: #CB7469;
}

.insuranceText {
    margin-right: 15px;
}

.large {
    font-weight: bold;
    padding: 20px 0;
    font-size: 1.5em;
    line-height: 1.2em;   
}

.medium {
    font-size: 1em;
    line-height: 1.3em;
}

.compensationWrapper {
    padding: 0;
    margin: 2rem 0.75rem 2rem 0;
    line-height: 1.3em;
    list-style-type: none;
}

.compensationTitle {
    font-size: 0.875rem;
}

.compensationItem {
    display: flex;
    padding-bottom: 20px;
}

.compensationItem:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.compensationText {
    margin-left: 0.35rem;
}

.compensationIcon img{
    height: 13px;
    width: 12px;
    vertical-align: middle;
}

.linksWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 2rem;
    align-items: center;
}

.linksWrapper > div {
    display: inline-flex;
}

.linksWrapper a {
    color: unset;
    text-decoration: none;
}

.link {
    color: #575757;
    font-size: 0.875em;
    line-height: 1.5em;
    cursor: pointer;
}

.link>a {
    color: #575757;
}

.link:hover {
    text-decoration: underline;
}

@media only screen and (min-width: 800px) {
    .compensationWrapper {
        margin: 1.5rem 0;
    }

    .compensationItem {
        padding-bottom: 1rem;
    }

    .soldItemName {
        padding: 0;
        font-size: 1.5em;
        line-height: 1em;
        margin-left: 0;
    }

    .soldItemName img {
        height: 15px;
        width: 11px;
    }

    .insuranceText {
        padding-bottom: 40px;
        font-weight: 700;
    }

    .insuranceText .large {
        font-size: 1.3em;
        padding: 0.5em 0;
    }

    .insuranceText .medium {
        font-size: 1em;
    }

    .insuranceSubheader {
        color: #9F9F9F;
    }
    
    .insuranceText {
        max-width: 50vw;
    }
    
    .compensationTitle {
        font-size: 18px;
    }

    .compensationText {
        font-size: 0.85em;
        font-weight: 700;
    }

    .linksWrapper {
        color: #9F9F9F;
        margin-top: 1rem;
        margin-right: 0.75rem;
        align-items: flex-end;
    }

    .linksWrapper > div {
        line-height: 18px;    
    }

    .link {
        font-size: 0.75em;
    }
  }
.titleImgWrapper, .titleImg {
    height: 5em;
    width: 5em;
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
}

.titleImg .logoImg {
    padding: 20px;
    height: 2em;
}

@media only screen and (min-width: 800px) {
    .titleImgWrapper {
        height: 29.5rem;
        width: 28rem;
    }
    
    .titleImg {
        height: 100%;
        width: 100%;
    }
}
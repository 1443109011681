.footer {
    text-align: center;
    font-size: 0.75em;
    line-height: 1.25em;
    margin-bottom: 100px;
}

.footer a {
    color: #575757;
    text-decoration: none;
}

.contactInfo {
    padding-top: 1em;
}

@media only screen and (min-width: 800px) {
    .footer {
        font-size: 0.625em;
        line-height: 1.3em;
        margin-bottom: 0;
    }
}
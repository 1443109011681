.errorWrapper {
    min-height: 100vh;
}

.error {
    background: rgba(255, 160, 122, .4);
    color: #D53720;
    min-height: 85vh;
    text-align: center
}

.thumbs {
    height: 40px;
    margin-top: 20vh;
}

.error p {
    font-size: 18px;
    margin: 0px 20vw;
}

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background: white;
    left: 0;
    margin: 1em 0;
}